import { Controller } from "@hotwired/stimulus";
import YoutubePlayer from "youtube-player";
export default class extends Controller {
  static values = {
    seekTime: Number,
  };
  static targets = [
    "link",
    "videoFrame",
    "toggleVideoFrame",
    "showVideoToggle",
  ];

  connect() {
    this.players = {};
    this.showPreview();
  }

  focus() {
    this.element.focus();
  }

  openStageSlect() {
    const element = this.element.querySelector('label[for="clear_stage_ids"]')
    element.click();
    element.scrollIntoView();
  }

  openOperatorSelect() {
    const element = this.element.querySelector('#clears__form__new_operator_select .choices')
    element.click();
    element.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }

  seekPrev() {
    this.seek(-1)
  }

  seekNext() {
    this.seek(1)
  }

  seekSkip() {
    this.seek(5)
  }

  seek(second) {
    const { player, currentSeek } = this.players[this.toggleVideoFrameTarget.id];
    if (!player) return;

    this.players[this.toggleVideoFrameTarget.id].currentSeek = currentSeek + second;
    player.seekTo(currentSeek + second, true);
    player.playVideo();
  }

  triggerSubmit() {
    this.element.querySelector('form').submit();
  }

  showVideo() {
    this.element.querySelector("#show_video_toggle").click();
    this.toggleVideoFrameTarget.scrollIntoView();
  }

  hideVideoFrame(target = null) {
    target = target || this.videoFrameTarget;
    if (this.players[target.id]) {
      this.players[target.id].player.destroy();
      this.players[target.id] = null;
    }
    target.classList.add("hidden");
    target.parentElement.classList.add("hidden");
    target.parentElement.setAttribute("aria-hidden", true);
    target.src = "";
  }

  highlight({ detail: { operatorId } }) {
    this.element
      .querySelectorAll(".operators__highlightable")
      .forEach((card) => {
        card.classList.remove("outline", "outline-primary");
        if (card.dataset.operatorId == operatorId) {
          card.classList.add("outline", "outline-primary");
        }
      });
  }

  showPreview() {
    const result = this.embedLink();
    if (result) {
      if (this.hasShowVideoToggleTarget) this.showVideoToggleTarget.classList.remove("sm:hidden");
      if (this.isShowingOperatorVideo()) this.showOperatorVideo();
    } else {
      if (this.hasShowVideoToggleTarget) this.showVideoToggleTarget.classList.add("sm:hidden");
      this.hideOperatorVideo();
    }
  }

  embedLink(target = null, seek = null) {
    target = target || (this.hasVideoFrameTarget && this.videoFrameTarget);
    if (!target) return false;

    if (!this.linkTarget.value.match(/http.*youtube.*watch\?v=/)) {
      this.hideVideoFrame();
      return false;
    }

    target.classList.remove("hidden");
    target.parentElement.classList.remove("hidden");
    target.parentElement.removeAttribute("aria-hidden");
    console.log({ id: target })
    const player = YoutubePlayer(target.id);
    this.players[target.id] =  { player, currentSeek: seek || 0 };

    const url = new URL(this.linkTarget.value);
    const videoId = new URLSearchParams(url.search).get("v");
    console.log({ videoId, seek })

    if (seek !== null) {
      player.loadVideoById(videoId, seek || 0);
      player.playVideo();
      player.on("stateChange", (event) => {
        if (event.data === 1) {
          player.pauseVideo();
        }
      })
    } else {
      player.loadVideoById(videoId);
      player.pauseVideo();
    }
    // const embedUrl = `https://youtube.com/embed/${videoId}`;

    // target.src = embedUrl;
    // if (seek) target.src += `?start=${seek}&autoplay=1`;
    return true;
  }

  isShowingOperatorVideo() {
    return this.showVideoToggleTarget.querySelector("input").checked;
  }

  toggleShowVideo(event) {
    if (event.target.checked) {
      this.showOperatorVideo();
    } else {
      this.hideOperatorVideo();
    }
  }

  get formContainerSoleChildClasses() {
    return ["lg:!w-1/2", "lg:!h-fit"];
  }

  showOperatorVideo() {
    if (!this.hasToggleVideoFrameTarget) return;
    this.embedLink(this.toggleVideoFrameTarget, this.seekTimeValue || 0);
  }

  hideOperatorVideo() {
    if (!this.hasToggleVideoFrameTarget) return;

    this.hideVideoFrame(this.toggleVideoFrameTarget);
  }
}
